import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p className="welcome">
      Welkom op de website van de Koningin Máxima School!
    </p>
    <p>
      De Koningin Máxima School is onderdeel van de Vereniging Nederlandse Taal en Cultuur Sofia (NTC Sofia) en biedt onderwijs aan zo'n 45 Nederlandstalige kinderen in Sofia in de leeftijd van 2,5- 18 jaar.
    </p>
    <p>
      De Koningin Máxima School is opgericht om de Nederlandse taal en cultuur een vaste plaats te geven in de ontwikkeling van Nederlandstalige kinderen in Bulgarije.
    </p>
    <p>
      Als school hechten we veel waarde aan om uitdagend onderwijs te bieden, zodat kinderen met plezier bezig zijn met de Nederlandse taal en cultuur. Tegelijkertijd is ons onderwijs zo ingericht dat kinderen die naar Nederland of Vlaanderen verhuizen, soepel kunnen instromen in het daar geboden onderwijs. Hiervoor maken we gebruik van lesmethoden die ook in Nederland worden gebruikt en werken onze leerlingen toe naar een CNAVT- examen, waarmee ze in Nederland of Vlaanderen toegelaten worden in het hoger onderwijs.
    </p>
    <p>
      Onze lessen op zaterdag vinden plaats in St. George International School & Preschool, een modern en goed uitgerust schoolgebouw in Sofia. Naast onze zaterdaglessen, waarin we veel aandacht besteden aan samen bezig zijn met spreek- en schrijfvaardigheid, taal (denk aan spreekwoorden, uitdrukkingen, etc.), cultuur en literatuur, hebben leerlingen vanaf groep 3 eens per week online les waarbij de focus vooral ligt op spelling en grammatica.
    </p>

  </Layout>
);

export default IndexPage;
